import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}>{`OtoSim Inc. is proud to share some of the peer-reviewed publications of an ever expanding list. These studies have scientifically proven that our products work.  Also other studies from around the world are currently underway using our products. If your Institution would like to conduct any research project using our technologies, we welcome the opportunity to assist or collaborate with you.`}</MDXTag>
<MDXTag name="p" components={components}>{`Feel free to contact us at:`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +1 647-792-7735 or`}</MDXTag>
<MDXTag name="p" components={components}>{`Email: `}<MDXTag name="a" components={components} parentName="p" props={{"href":"mailto:info@otosim.com"}}>{`info@otosim.com`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Publications`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`1.  An innovative and safe way to train novice ear nose and throat residents through simulation: the SimORL experience.`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Dell'Era V, Garzaro M, Carenzo L, Ingrassia PL, Aluffi Valletti P.`}</MDXTag>
<MDXTag name="p" components={components}>{`Acta Otorhinolaryngol Ital. 2020 Feb;40(1):19-25. doi: 10.14639/0392-100X-N0128.`}</MDXTag>
<MDXTag name="p" components={components}>{`PMID: 32275645`}</MDXTag>
<MDXTag name="p" components={components}>{`   `}<MDXTag name="strong" components={components} parentName="p">{`CONCLUSION:`}</MDXTag>{` The SimORL experience demonstrated that simulation for ENT residents can be a valid educational tool to improve confidence in performing specific ENT procedures, showing a very high overall global satisfaction rate.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`2.  Effectiveness of discovery learning using a mobile otoscopy simulator on knowledge acquisition and retention in medical students: a randomized controlled trial.`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Xu J, Campisi P, Forte V, Carrillo B, Vescan A, Brydges R.`}</MDXTag>
<MDXTag name="p" components={components}>{`J Otolaryngol Head Neck Surg. 2018 Nov 20;47(1):70. doi: 10.1186/s40463-018-0317-4.`}</MDXTag>
<MDXTag name="p" components={components}>{`PMID: 30458877`}</MDXTag>
<MDXTag name="p" components={components}>{`   `}<MDXTag name="strong" components={components} parentName="p">{`CONCLUSION:`}</MDXTag>{` There is value in self-regulated learning (SRL} in simulation education, and we plan to further improve our curricular design by considering learner behaviours identified in this study.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`3.  Evaluation of a Web-Based Module and an Otoscopy Simulator in Teaching Ear Disease.`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Wu V, Beyea JA.`}</MDXTag>
<MDXTag name="p" components={components}>{`Otolaryngol Head Neck Surg. 2017 Feb;156(2):272-277. doi: 10.1177/0194599816677697. Epub 2016 Nov 14.`}</MDXTag>
<MDXTag name="p" components={components}>{`PMID: 28145842`}</MDXTag>
<MDXTag name="p" components={components}>{`   `}<MDXTag name="strong" components={components} parentName="p">{`CONCLUSION:`}</MDXTag>{` Otoscopy clinical skills increased and were retained only in otoscopy simulation (OS). Preclerkship medical student acquisition and retention of otolaryngology diagnostic skills can be greatly improved through web-based teaching modules and otoscopy simulation.`}</MDXTag>
<MDXTag name="p" components={components}>{` `}<MDXTag name="strong" components={components} parentName="p">{`4.  Ear Disease Knowledge and Otoscopy Skills Transfer to Real Patients: A Randomized Controlled Trial.`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Wu V, Sattar J, Cheon S, Beyea JA.`}</MDXTag>
<MDXTag name="p" components={components}>{`J Surg Educ. 2018 Jul - Aug;75(4):1062-1069. doi: 10.1016/j.jsurg.2017.12.011. Epub 2018 Jan 19.`}</MDXTag>
<MDXTag name="p" components={components}>{`PMID: 29371080`}</MDXTag>
<MDXTag name="p" components={components}>{`   `}<MDXTag name="strong" components={components} parentName="p">{`CONCLUSION:`}</MDXTag>{` Otoscopy clinical skills were found to have increased only in otoscopy simulation (OS) and standard classroom instruction (SI), with the OS group demonstrating the largest improvement. Simulation-based medical education in Otolaryngology may provide the greatest transfer of medical knowledge and technical skills when evaluated with real patients.`}</MDXTag>
<MDXTag name="p" components={components}>{` `}<MDXTag name="strong" components={components} parentName="p">{`5.  Otoscopy simulation training in a classroom setting: a novel approach to teaching otoscopy to medical students.`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Davies J, Djelic L, Campisi P, Forte V, Chiodo A.`}</MDXTag>
<MDXTag name="p" components={components}>{`Laryngoscope. 2014 Nov;124(11):2594-7. doi: 10.1002/lary.24682. Epub 2014 Aug 28.`}</MDXTag>
<MDXTag name="p" components={components}>{`PMID: 24648271`}</MDXTag>
<MDXTag name="p" components={components}>{`   `}<MDXTag name="strong" components={components} parentName="p">{`CONCLUSION:`}</MDXTag>{` Organizing large-group otoscopy simulator training sessions is one method whereby students can become familiar with a wide variety of pathologies of the ear and improve both their diagnostic accuracy and their confidence in making otologic diagnoses.`}</MDXTag>
<MDXTag name="p" components={components}>{` `}<MDXTag name="strong" components={components} parentName="p">{`6.  Objective Evaluation of Otoscopy Skills Among Family and Community Medicine, Pediatric, and Otolaryngology Residents.`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Oyewumi M, Brandt MG, Carrillo B, Atkinson A, Iglar K, Forte V, Campisi P.`}</MDXTag>
<MDXTag name="p" components={components}>{`J Surg Educ. 2016 Jan-Feb;73(1):129-35. doi: 10.1016/j.jsurg.2015.07.011. Epub 2015 Sep 11.`}</MDXTag>
<MDXTag name="p" components={components}>{`PMID: 26364889`}</MDXTag>
<MDXTag name="p" components={components}>{`   `}<MDXTag name="strong" components={components} parentName="p">{`CONCLUSION:`}</MDXTag>{` A single teaching session with an otoscopy simulator significantly improved diagnostic accuracy in primary care and OTO-HNS trainees.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`7.  Evaluation of an otoscopy simulator to teach otoscopy and normative anatomy to first year medical students.`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Lee DJ, Fu TS, Carrillo B, Campisi P, Forte V, Chiodo A.`}</MDXTag>
<MDXTag name="p" components={components}>{`Laryngoscope. 2015 Sep;125(9):2159-62. doi: 10.1002/lary.25135. Epub 2015 Jan 19.`}</MDXTag>
<MDXTag name="p" components={components}>{`PMID: 25601719`}</MDXTag>
<MDXTag name="p" components={components}>{`   `}<MDXTag name="strong" components={components} parentName="p">{`CONCLUSION:`}</MDXTag>{` The use of otoscopy simulation is a novel addition to traditional learning methods for undergraduate medical students. Students can effectively learn normal external and middle ear anatomy and improve their confidence in performing otoscopy examination.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`8.  Evaluation of an Ophthalmoscopy Simulator to Teach Funduscopy Skills to Pediatric Residents.`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Kouzmitcheva E, Grover SA, Berenbaum T, Ali A, Atkinson A, Yeh EA.`}</MDXTag>
<MDXTag name="p" components={components}>{`Can J Neurol Sci. 2018 May;45(3):320-324. doi: 10.1017/cjn.2017.291. Epub 2018 Feb 19.`}</MDXTag>
<MDXTag name="p" components={components}>{`PMID: 29455676`}</MDXTag>
<MDXTag name="p" components={components}>{`   `}<MDXTag name="strong" components={components} parentName="p">{`CONCLUSION:`}</MDXTag>{` A single session with an ophthalmoscopy simulator can improve diagnostic accuracy in postgraduate pediatric trainees. Use of ophthalmoscopy simulation represents a novel addition to traditional learning methods for postgraduate pediatric residents that can help trainees to improve their confidence and accuracy in performing this challenging examination.`}</MDXTag></MDXTag>

export const _frontmatter = {};

  