import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Img from 'gatsby-image';
import { Box, Heading, Text } from 'grommet';
import SectionTitle from 'components/title/sectionTitle';
import { CoverSlider, ClientSlider } from 'components/slider';
import RouteButton from 'components/routeButton';

import Column1 from '../../content/publications/publicationslist.mdx';

const ProductCard = ({ title, text, pic, link }) => (
  <Box
    alignSelf="start"
    background="light-3"
    pad="small"
    round="small"
    direction="row-responsive"
    margin="small"
    style={{ minHeight: '325px' }}
  >
    <Box margin="small" style={{ width: '100%', maxWidth: '300px' }}>
      <Heading
        level="3"
        size="small"
        responsive={true}
        margin="small"
        alignSelf="start"
      >
        {title}
      </Heading>
      <Text margin="small" alignSelf="start">
        {text}
      </Text>
      <Box alignSelf="start" width="100%">
        <RouteButton to={link} label="Learn More" />
      </Box>
    </Box>

    <Box margin="small" alignSelf="center" width="small">
      <Img fluid={pic ? pic.childImageSharp.fluid : {}} alt="Product Picture" />
    </Box>
  </Box>
);

ProductCard.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  pic: PropTypes.object.isRequired,
  link: PropTypes.string,
};

const Index = ({ data }) => (
  <Layout>
    <Box fill="horizontal">
      <CoverSlider images={data.homeJson.coverImages} />

      <Box fill="horizontal" background="white" style={{ zIndex: '1' }}>
        <SectionTitle title='"Better Ear and Eye Training, Better Outcomes"' />
        <SectionTitle title='Simulation products used by over 650 prominent institutions and thousands of students worldwide.'/>
        <Box alignSelf="center" alignContent="start" direction="row-responsive">
          {data.homeJson.products.slice(0, 2).map((product, i) => (
            <ProductCard {...product} key={i} /> 
          ))}
        </Box>
     
        
    
      <Box fill="horizontal" background="white" style={{ zIndex: '1' }}>
        <SectionTitle title="New Mobile Products" />
        <Box alignSelf="center" alignContent="start" direction="row-responsive">
          {data.homeJson.products.slice(2, 4).map((product, i) => (
            <ProductCard {...product} key={i} /> 
          ))}
        </Box>
        <Box fill="horizontal" background="white" style={{ zIndex: '1' }}>
        <Box alignSelf="center" alignContent="start" direction="row-responsive">
          {data.homeJson.products.slice(4, 5).map((product, i) => (
            <ProductCard {...product} key={i} /> 
          ))}
        </Box>
        
        <SectionTitle title="Clients" />

        <ClientSlider clients={data.homeJson.clients} />
        
        
         <SectionTitle title="Publications" />
         
         <Box alignSelf="center" width="xlarge" pad="small">
         
         <Column1 />
         </Box>
        

        <SectionTitle title="Latest News" />

        <Box alignSelf="center" width="large">
          <a
            className="twitter-timeline"
            data-theme="light"
            data-link-color="#00739D"
            data-tweet-limit="3"
            data-chrome="nofooter"
            data-dnt="true"
            href="https://twitter.com/OtoSimInc?ref_src=twsrc%5Etfw"
          >
            Tweets by OtoSimInc
          </a>
        </Box>
      </Box>
      </Box>
      </Box>
    </Box>
  </Layout>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      products {
        title
        text
        pic {
          childImageSharp {
            fluid(maxWidth: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        link
      }
      coverImages {
        image {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        text
        subtext
        link
        background
      }
      clients {
        image {
          childImageSharp {
            fluid(maxWidth: 200, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
